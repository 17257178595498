import React from 'react'
import styles from "../../styles/analyse.module.css";

const Formtextblock = ({register, text, required}) => {


    return (
        <label className={styles.textformwrapper}>
        <span className={styles.textformdescription}>{text}</span> 
        <textarea ref = {register} name = {text} required = {required} rows="10"/>   


        </label>
    )
}

export default Formtextblock
//{`type=${type} name=${text} ${required ? "required" : ""} ref=${register}`} 