import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Layout from "../components/layout"
import styles from "../styles/analyse.module.css"
import Forminputtext from "../components/Form/forminputtext"
import SEO from "../components/seo"
import Formtextblock from "../components/Form/formtextblock"
import { Link } from "gatsby"
import Privacycheck from "../components/Form/privacycheck"

const Contact = () => {
  const [success, setSuccess] = useState("empty")
  const { register, handleSubmit } = useForm()
  const [privacy, setPrivacy] = useState(false)
  const [privacyAlert, setPrivacyAlert] = useState(false)

  const onSubmit = data => {
    fetch("https://formspree.io/f/mgepgbap", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(data => {
      console.log(data)
      console.log(data.status)

      if (data.status === 200) {
        setSuccess("success")
      } else {
        setSuccess("failed")
      }
    })
  }

  const privacyHandler = () => {
    setPrivacy(!privacy)
  }

  const privacyCheck = () => {
    if (!privacy) {
      setPrivacyAlert(true)
    } else {
      setPrivacyAlert(false)
    }
  }

  if (success === "empty") {
    return (
      <Layout>
        <SEO title="Kontakt" />

        <h1>Sende mir eine Nachricht</h1>

        <p>
          Schreib mir eine Nachricht und ich werde mich schnellstmöglich bei dir
          melden.
        </p>

        <div className={styles.mainFormWrapper}>
          <form method="POST" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formWrapper}>
              <Forminputtext
                text="Vorname*"
                type="text"
                required
                register={register}
              />
              <Forminputtext
                text="Name*"
                type="text"
                required
                register={register}
              />
              <Forminputtext
                text="eMail*"
                type="email"
                required
                register={register}
              />
              <Forminputtext text="Telefon" type="tel" register={register} />
              <Formtextblock text="Nachricht" register={register} />

              <Privacycheck register={register} privacy={privacyHandler} />
            </div>

            {privacyAlert ? <h1>Bitte bestätige den Datenschutz</h1> : null}
            <input
              onClick={privacyCheck}
              className={styles.submit}
              type="submit"
            />

            <input type="text" name="_gotcha" style={{ display: "none" }} />
          </form>
        </div>
      </Layout>
    )
  } else if (success === "success") {
    return (
      <Layout>
        <div className={styles.success}>
          <h1>
            Vielen Dank, ich habe das Formular erhalten und melde mich bei dir!
          </h1>
        </div>
      </Layout>
    )
  } else if (success === "failed") {
    return (
      <Layout>
        <h1>
          Das hat leider nicht funktioniert. Bitte versuch es nochmal oder
          kontaktiere mich direkt!
        </h1>
      </Layout>
    )
  }
}

export default Contact
